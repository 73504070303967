import { defineStore } from 'pinia'

export interface DictionaryItem {
  id: number
  name: string
  value: string
  status: number
  dictId: number
}

interface DictionaryType {
  id: number
  label: string
  code: string
  items: DictionaryItem[]
}

interface DictionaryState {
  userType: DictionaryItem[]
  userStatus: DictionaryItem[]
  updateSwitch:DictionaryItem[]
  customerStatus:DictionaryItem[]
  languagesType:DictionaryItem[]
  lotteryOrderStatus:DictionaryItem[]
  lotteryCode:DictionaryItem[]
  controlsGameStatus:DictionaryItem[]
  effectRange:DictionaryItem[]
  orderStatus:DictionaryItem[]
  activityType:DictionaryItem[]
  mailStatus:DictionaryItem[]
  mailType:DictionaryItem[]
  carouselStatus:DictionaryItem[]
  carouselType:DictionaryItem[]
  gameMode:DictionaryItem[]
  gameStatus:DictionaryItem[]
  registerDevice:DictionaryItem[]
  accountType:DictionaryItem[]
  agentLevel:DictionaryItem[]
  fundChangeType:DictionaryItem[]
  retainedType:DictionaryItem[]
}

export const useDictionaryStore = defineStore('dictionary', {
  state: (): DictionaryState => ({
    userType: [],
    userStatus: [],
    updateSwitch:[],
    customerStatus:[],
    languagesType:[],
    lotteryOrderStatus:[],
    lotteryCode:[],
    controlsGameStatus:[],
    effectRange:[],
    orderStatus:[],
    activityType:[],
    mailStatus:[],
    mailType:[],
    carouselStatus:[],
    carouselType:[],
    gameMode:[],
    gameStatus:[],
    registerDevice:[],
    accountType:[],
    agentLevel:[],
    fundChangeType:[],
    retainedType:[],
  }),
  actions: {
    setDictionaryData(data: DictionaryType[]): void {
      data.forEach((type) => {
        switch (type.code) {
          case 'user_type':
            this.userType = type.items
            break
          case 'user_status':
            this.userStatus = type.items
            break
          case 'update_switch':
            this.updateSwitch = type.items
            break
          case 'customer_status':
            this.customerStatus = type.items
            break
          case 'languages_type':
            this.languagesType = type.items
            break
          case 'lottery_order_status':
            this.lotteryOrderStatus = type.items
            break
          case 'lottery_code':
            this.lotteryCode = type.items
            break
          case 'controls_game_status':
            this.controlsGameStatus = type.items
            break
          case 'effect_range':
            this.effectRange = type.items
            break
          case 'order_status':
            this.orderStatus = type.items
            break
          case 'activity_type':
            this.activityType = type.items
            break
          case 'mail_status':
            this.mailStatus = type.items
            break
          case 'mail_type':
            this.mailType = type.items
            break
          case 'carousel_status':
            this.carouselStatus = type.items
            break
          case 'carousel_type':
            this.carouselType = type.items
            break
          case 'game_mode':
            this.gameMode = type.items
            break
          case 'game_status':
            this.gameStatus = type.items
            break
          case 'register_device':
            this.registerDevice = type.items
            break
          case 'account_type':
            this.accountType = type.items
            break
          case 'agent_level':
            this.agentLevel = type.items
            break
          case 'fund_change_type':
            this.fundChangeType = type.items
            break
          case 'retained_type':
            this.retainedType = type.items
            break
            
          default:
            break
        }
      })
    },
  },
})

export function getDictionaryState(): DictionaryState {
  return useDictionaryStore() as DictionaryState
}
