<route lang="yaml">
name: home
title: 主页
icon: ant-design:home-twotone
</route>

<script setup lang="ts">
import { onMounted } from 'vue'
import api from '@/api/modules/home'
import * as echarts from 'echarts';

defineOptions({
  name: 'Index',
})

const {
  pagination,
  getParams,
  onSizeChange,
  onCurrentChange,
} = usePagination()

interface DataListItem {
  date: string
  registered: number
  active: number
  recharge: number
  withdraw: number
}

const data = ref({
  // 批量操作
  loading: false,
  // 表格是否自适应高度
  tableAutoHeight: false,
  batch: {
    enable: false,
    selectionDataList: [],
  },
  searchFold: true,
  textData: {
    newUser: 0,
    loginUser: 0,
    activeUser: 0,
    betUser: 0,
    newPlayer: 0,
    newAgent: 0,
    betPlayer: 0,
    betAgent:0,
    totalRecharge:0,
    totalWithdrawal:0,
    totalProfit:0,
  },
  chats:[],
  chatDatas: {
    date: [] as any,
    registered: [] as any,
    active: [] as any,
    recharge: [] as any,
    withdraw: [] as any,
  },
  charts: {},
 
  // 搜索
  search: {
    changeTime:'',
  },
})

onMounted(() => {
  getDataList()
})

function getDataList() {
  let _startTime = ''
  let _endTime = ''
  if (data.value.search.changeTime !== null && data.value.search.changeTime.length > 0) {
    _startTime = data.value.search.changeTime[0]
    _endTime = data.value.search.changeTime[1]
  }
  const apiParams = {
    startTime: _startTime,
    endTime: _endTime,
  }
  api.homeList(apiParams).then((res: any) => {
    data.value.textData = res.data.textData
    
    res.data.chatData.forEach((item:any) => {
      data.value.chatDatas.date.push(item.date)
      data.value.chatDatas.active.push(item.active)
      data.value.chatDatas.recharge.push(item.recharge)
      data.value.chatDatas.registered.push(item.registered)
      data.value.chatDatas.withdraw.push(item.withdraw)
    });
    echatData()
  })
 
}


function refreshData() {
  getDataList()
}


function resetQuery() {
  data.value.search = {
    changeTime:''
  }
  getDataList()
}

function echatData(){
  var chartDom = document.getElementById('chatData');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['注册人数', '活跃人数', '充值人数', '提现人数']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.value.chatDatas.date
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '注册人数',
        type: 'line',
        stack: 'Total',
        data:  data.value.chatDatas.registered
      },
      {
        name: '活跃人数',
        type: 'line',
        stack: 'Total',
        data: data.value.chatDatas.active
      },
      {
        name: '充值人数',
        type: 'line',
        stack: 'Total',
        data: data.value.chatDatas.recharge
      },
      {
        name: '提现人数',
        type: 'line',
        stack: 'Total',
        data: data.value.chatDatas.withdraw
      },
    ]
  };

  option && myChart.setOption(option);
}

</script>

<template>
  <div :class="{ 'absolute-container': data.tableAutoHeight }">
    <page-main>
      <search-bar :fold="data.searchFold" :show-toggle="false">
        <el-form
          :model="data.search" label-width="70px" inline inline-message
          class="inline-form-item"
        >
          <el-form-item class="right-align">
            <el-form-item label="创建时间" prop="startTime">
              <el-date-picker
                v-model="data.search.changeTime"
                style="width: 400px;"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-button type="primary" @click="getDataList()">
                <template #icon>
                  <el-icon>
                    <svg-icon name="i-ep:search" />
                  </el-icon>
                </template>
                搜索
              </el-button>
              <el-button type="primary" @click="resetQuery">
                <template #icon>
                  <el-icon>
                    <svg-icon name="i-ep:refresh" />
                  </el-icon>
                </template>
                清空
              </el-button>
          </el-form-item>
        </el-form>
      </search-bar>
      <page-header title="数据中心" style="margin-bottom:0px;" />
      <el-row style="width:90%;height:100px; padding-top: 1%;padding-left: 2%;padding-right: 2%;">
        <el-col :span="4">
          <el-card class="el-card-style1" style="font-size: 12px;height:80px " body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>新增用户</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.newUser }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style2" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>登录用户</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.loginUser }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style3" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>在线用户</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.activeUser }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style4" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>活跃用户</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.betUser }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style5" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>新增玩家</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.newPlayer }}</span>
          </el-card>
        </el-col>
      </el-row>

      <el-row style="width:90%;height:100px;  padding-top: 1%;padding-left: 2%;padding-right: 2%;">
        <el-col :span="4">
          <el-card class="el-card-style5" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>新增代理</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.newAgent }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style4" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>活跃玩家</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.betPlayer }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style2" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>活跃代理</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.betAgent }}</span>
          </el-card>
          </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style1" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>总充值</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.totalRecharge }}</span>
          </el-card>
          </el-col>
        <el-col :span="1" />
        <el-col :span="4">
          <el-card class="el-card-style3" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>总提现</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.totalWithdrawal }}</span>
          </el-card>
          </el-col>
      </el-row>
      <el-row style="width:90%;height:100px; padding-top: 1%;padding-left: 2%;padding-right: 2%;">
        <el-col :span="4">
          <el-card class="el-card-style3" style="font-size: 12px;height:80px" body-style="text-align:center;">
            <template #header>
              <div class="card-header" style="height: 2px;">
                <span>总盈利</span>
              </div>
            </template>
            <span style="text-align: center;">{{ data.textData.totalProfit }}</span>
          </el-card>
        </el-col>
        <el-col :span="1" />
      </el-row>

      <page-header title="数据统计" style="margin-bottom:-50px;" />
	    <div style="min-height: 300px;min-width: 900px;" id="chatData" class="app-echarts"></div>
    
       
    </page-main>
  </div>
</template>

<style scoped>
table {
  margin-top: 50px;
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}
</style>

<style lang="scss" scoped >
.el-card-style1{
margin:0 auto;
::v-deep .el-card__header{
    background-color:rgb(89, 201, 201);
    text-align:center;
    color:#fff
}
}
.el-card-style2{
margin:0 auto;
::v-deep .el-card__header{
    background-color:rgb(196, 126, 61);
    text-align:center;
    color:#fff
}
}
.el-card-style3{
margin:0 auto;
::v-deep .el-card__header{
    background-color:rgb(214, 74, 74);
    text-align:center;
    color:#fff
}
}
.el-card-style4{
margin:0 auto;
::v-deep .el-card__header{
    background-color:rgb(92, 48, 163);
    text-align:center;
    color:#fff
}
}
.el-card-style5{
margin:0 auto;
::v-deep .el-card__header{
    background-color:rgb(104, 116, 39);
    text-align:center;
    color:#fff
}
}
.bar-charts-div {
	    overflow:hidden;
	    height:100%;
	    width:100%;
	}
	.app-echarts {
	    width: 100%;
	    height: 100%;
	}
</style>