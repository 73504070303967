import { defaultsDeep } from 'lodash-es'
import type { RecursiveRequired, Settings } from '#/global'
import settingsDefault from '@/settings.default'

/*
*
* 不要动settings.default.ts
*
* */

const globalSettings: Settings.all = {
  app: {
    enableAppSetting: true,
    elementSize: 'large',
  },
  navSearch: {
    enable: false,
    enableHotkeys: false,
  },
  topbar: {
    mode: 'fixed',
  },
  tabbar: {
    enable: true,
    enableIcon: true,
    enableMemory: true,
    enableHotkeys: true,
  },
  toolbar: {
    enableI18n: false,
  },
}

export default defaultsDeep(globalSettings, settingsDefault) as RecursiveRequired<Settings.all>
