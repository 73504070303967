import path from 'path-browserify'

export function resolveRoutePath(basePath?: string, routePath?: string) {
  return basePath ? path.resolve(basePath, routePath ?? '') : routePath ?? ''
}
/**
 * @description 父子关系的数组转换成树形结构数据
 */
export function translateDataToTree(data: any) {
  const parent = data.filter((value: any) => {
    return value.parentId === 'undefined' || value.parentId === null || value.parentId === 0
  })
  const children = data.filter(
    (value: any) => {
      return value.parentId !== 'undefined'
        && value.parentId !== null
        && value.parentId !== 0
    },
  )
  const translator = (parent: any, children: any) => {
    parent.forEach((parent: any) => {
      children.forEach((current: any, index: any) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          // current.breadcrumb = false
          translator([current], temp)
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  translator(parent, children)
  return parent
}
// 格式化后端路由数据
export function formatBackRoutes(routes: any): [] {
  // console.log(views)
  return routes.map((route: any) => {
    route.meta = {
      title: route.title,
      icon: route.icon,
      isCustomSvg: route.isCustomSvg !== 0,
      noKeepAlive: route.noKeepAlive !== 0,
      affix: route.affix !== 0,
      badge: route.badge,
      tabHidden: route.tabHidden !== 0,
      name: route.name,
      sidebar: route.sidebar !== 0,
      breadcrumb: route.breadcrumb !== 0,
    }
    if (route.children) {
      route.children = formatBackRoutes(route.children)
    }
    return route
  })
}
