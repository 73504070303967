import api from '../index'

export default {
  list: () => api.get('dictionary/list'),

  listAll: () => api.get('dictionary/listAll'),

  create: (data: any) => api.post('dictionary/create', data),

  edit: (data: any) => api.post('dictionary/edit', data),

  delete: (id: number | string) => api.post('dictionary/delete', {
    id,
  }),

  itemList: (data: {
    dictionary_id: number | string
  }) => api.get('dictionary/item/list', {
    params: data,
  }),

  itemChangeEnable: (data: {
    id: number
    status: number
  }) => api.post('dictionary/item/change/status', data),

  detail: (id: number | string) => api.get('dictionary/detail', {
    params: {
      id,
    },
  }),

  itemDetail: (id: number | string) => api.get('dictionary/item/detail', {
    params: {
      id,
    },
  }),

  itemCreate: (data: any) => api.post('dictionary/item/create', data),

  itemEdit: (data: any) => api.post('dictionary/item/edit', data),

  itemDelete: (id: any) => api.post('dictionary/item/delete', {
    id,
  }),
}
