import axios from 'axios'

// import qs from 'qs'
import { ElMessage } from 'element-plus'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'

const api = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
})

/*
 * @description 驼峰转下划线
 * @param object
 * @returns object
 * */
function objectHumpToLine(obj: { [key: string]: any }): { [key: string]: any } {
  const result: { [key: string]: any } = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // 执行相应的操作
      const convertedKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
      const value = obj[key]

      if (typeof value === 'object' && value !== null) {
        result[convertedKey] = objectHumpToLine(value)
      }
      else {
        result[convertedKey] = value
      }
    }
  }

  return result
}

api.interceptors.request.use(
  (request) => {
    // 全局拦截请求发送前提交的参数
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    // 设置请求头
    if (request.headers) {
      request.headers['Accept-Language'] = settingsStore.settings.app.defaultLang
      if (userStore.isLogin) {
        request.headers.Token = userStore.token
      }
    }
    // 是否将 POST 请求参数进行字符串化处理
    // if (request.method === 'post') {
    //   request.data = qs.stringify(request.data, {
    //     arrayFormat: 'brackets',
    //   })
    // }
    request.data = objectHumpToLine(request.data)
    return request
  },
)

api.interceptors.response.use(
  (response) => {
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 假设返回数据格式为：{ status: 1, error: '', data: '' }
     * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    if (response.data.code === 1) {
      if (response.data.msg !== '') {
        // 这里做错误提示，如果使用了 element plus 则可以使用 Message 进行提示
        ElMessage.error(response.data.msg)
        return Promise.reject(response.data)
      }
    }
    // else {
    //   useUserStore().logout()
    // }
    return Promise.resolve(response.data)
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    }
    ElMessage({
      message,
      type: 'error',
    })
    return Promise.reject(error)
  },
)

export default api
